/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved
US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { apolloClientFactory } from '@exo/frontend-common-apollo';
import { ApplicationConfig } from '@exo/frontend-common-app-shell';
import { loadable } from '@exo/frontend-apps-base';
import { AppWrapper } from './client/AppWrapper';

/* @ts-ignore */
const featureFlag = s => s?.toUpperCase() === 'TRUE';

const client = apolloClientFactory({
  batch: process.env.GRAPHQL_BATCH === 'true',
  endpoint: process.env.GRAPHQL_ENDPOINT!
});

const modules = {
  home: loadable(() => import('@exo/frontend-features-insurance-home-ui/client/App')),
  account: loadable(() => import('@exo/frontend-features-insurance-account-ui/client/App')),
  register: loadable(() => import('@exo/frontend-features-insurance-register-ui/client/App')),
  admin: loadable(() => import('@exo/frontend-features-insurance-admin-ui/client/App')),
  polarisUser: loadable(
    () => import('@exo/frontend-features-insurance-polaris-users-ui/client/App')
  ),
  insurerGroup: loadable(
    () => import('@exo/frontend-features-insurance-insurer-group-ui/client/App')
  ),
  endpoint: loadable(() => import('@exo/frontend-features-insurance-endpoint-ui/client/App')),
  insurer: loadable(() => import('@exo/frontend-features-insurance-insurer-ui/client/App')),
  departments: loadable(() => import('@exo/frontend-features-insurance-departments-ui/client/App')),
  intermediaryGroups: loadable(
    () => import('@exo/frontend-features-insurance-intermediary-groups-ui/client/App')
  ),
  intermediary: loadable(
    () => import('@exo/frontend-features-insurance-intermediary-ui/client/App')
  ),
  integratorGroup: loadable(
    () => import('@exo/frontend-features-insurance-integrator-group-ui/client/App')
  ),
  email: loadable(() => import('@exo/frontend-features-insurance-email-ui/client/App')),
  sensitiveData: loadable(
    () => import('@exo/frontend-features-insurance-sensitive-data-rules-ui/client/App')
  ),
  myHome: loadable(() => import('@exo/frontend-features-insurance-my-home-ui/client/App')),
  reportScheduler: loadable(
    () => import('@exo/frontend-features-insurance-report-scheduler-ui/client/App')
  ),
  services: loadable(() => import('@exo/frontend-features-insurance-services-ui/client/App')),
  integrator: loadable(() => import('@exo/frontend-features-insurance-integrator-ui/client/App')),
  applications: loadable(
    () => import('@exo/frontend-features-insurance-applications-ui/client/App')
  ),
  serviceIndex: loadable(
    () => import('@exo/frontend-features-insurance-service-index-ui/client/App')
  ),
  templates: loadable(() => import('@exo/frontend-features-insurance-templates-ui/client/App')),
  classificationGroups: loadable(
    () => import('@exo/frontend-features-insurance-classification-group-ui/client/App')
  ),
  registerNewInsurer: loadable(
    () => import('@exo/frontend-features-insurance-register-new-insurer-ui/client/App')
  ),
  userRoles: loadable(() => import('@exo/frontend-features-insurance-user-roles-ui/client/App')),
  intermediariesIbls: loadable(
    () => import('@exo/frontend-features-insurance-intermediaries-ibls-ui')
  ),
  maintainServices: loadable(
    () => import('@exo/frontend-features-insurance-maintain-services-ui/client/App')
  ),
  productsServices: loadable(
    () => import('@exo/frontend-features-insurance-products-services-ui/client/App')
  ),
  newsItems: loadable(() => import('@exo/frontend-features-insurance-news-items-ui/client/App')),
  liveChat: loadable(() => import('@exo/frontend-features-insurance-live-chat-ui/client/App')),
  applicationStatus: loadable(() => import('@exo/frontend-features-insurance-application-status-ui/client/App'))
};

export default {
  bundles: [
    {
      id: 'ROOT',
      url: 'http://localhost:3000',
      modules: {
        '/home': modules.home,
        '/account': modules.account,
        '/register': modules.register,
        '/admin/users': modules.polarisUser,
        '/admin/insurer-users': modules.polarisUser,
        '/admin/insurer-group': modules.insurerGroup,
        '/admin/insurer': modules.insurer,
        '/admin/endpoint': modules.endpoint,
        '/admin/insurer-departments': modules.departments,
        '/admin/intermediaries-ibls': modules.intermediariesIbls,
        '/admin/intermediary-departments': modules.departments,
        '/admin/intermediary-groups': modules.intermediaryGroups,
        '/admin/intermediary-users': modules.polarisUser,
        '/admin/intermediary': modules.intermediary,
        '/admin/integrator-group': modules.integratorGroup,
        '/admin/applications': modules.applications,
        '/admin/classification-groups/': modules.classificationGroups,
        '/admin/register-new-insurer': modules.registerNewInsurer,
        '/admin/news-items': modules.newsItems,
        '/admin/live-chat': modules.liveChat,
        '/admin/application-status': modules.applicationStatus,
        '/email': modules.email,
        '/admin/sensitive-data-rules': modules.sensitiveData,
        '/my-home': modules.myHome,
        '/report-scheduler': modules.reportScheduler,
        '/maintain-services/templates': modules.templates,

        '/admin/integrator': modules.integrator,
        '/maintain-services/service-index': modules.serviceIndex,
        '/maintain-services/user-roles': modules.userRoles,

        '/maintain-services/services': modules.services,

        '/products-services': modules.productsServices,

        '/maintain-services': modules.maintainServices,
        '/admin': modules.admin
      }
    }
  ],
  redirects: {
    '/': '/home/welcome',
    '/email': '/email/inbox'
  },
  appWrapper: AppWrapper,
  featureConfig: {
    endpoints: {
      oauthLink: process.env.OAUTH_ENDPOINT,
      enabledCors: process.env.CORS_ENABLED,
      logOutEndpoint: `${process.env.OAUTH_ENDPOINT}${process.env.LOGOUT_ENDPOINT}`,
      tokenEndpoint: process.env.TOKEN_ENDPOINT,
      authLink: `${process.env.OAUTH_ENDPOINT}${process.env.AUTH_ENDPOINT}`,
      clientId: process.env.CLIENT_ID
    },
    chrome: {
      loadingTimeout: process.env.LOADING_TIMEOUT,
      meta: {
        title: 'imarket',
        icon: 'data:;base64,iVBORw0KGgo='
      },
      header: {
        extensions: {
          icons: [],
          extraHeaders: []
        },
        homeLink: process.env.MAIN_APP_URL,
        fixedLinks: [
          { href: `/home/welcome`, label: 'Welcome' },
          { href: `/register/register-intro`, label: 'Register' },
          { href: process.env.GENERAL_LINKS_IMARKET, label: 'About imarket', target: '_blank' }
        ]
      },
      footer: {
        copyright: `Polaris U.K LTD ${new Date().getFullYear()}. All rights reserved.`,
        sectionLeft: [
          {
            title: 'section-left',
            items: [
              {
                title: 'copyright'
              },
              {
                title: 'termsandco'
              }
            ]
          }
        ],
        sectionRight: [
          {
            title: 'section-right',
            items: [
              {
                title: 'Terms & Conditions',
                url: process.env.FOOTER_LINKS_TERMS
              },
              {
                title: 'Privacy Statement',
                url: process.env.FOOTER_LINKS_PRIVACY
              },
              {
                title: 'Copyright',
                url: process.env.FOOTER_LINKS_COPYRIGHT
              },
              {
                title: 'Contact Us',
                url: process.env.FOOTER_LINKS_CONTACT
              },
              {
                title: 'Help',
                url: process.env.FOOTER_LINKS_HELP
              }
            ]
          }
        ]
      }
    },
    insuranceAccount: {
      contactNumber: process.env.CONTACT_NUMBER1,
      contactNumber2: process.env.CONTACT_NUMBER2
    },
    registration: {
      linkContacts: process.env.GENERAL_LINKS_CONTACTS,
      linkWelcomePdf: process.env.GENERAL_LINKS_WELCOME_PDF,
      linkTerms: process.env.GENERAL_LINKS_TERMS,
      email: process.env.EMAIL,
      phone1: process.env.CONTACT_NUMBER1,
      phone2: process.env.CONTACT_NUMBER2
    },
    usefulLinks: {
      mainWebsite: process.env.GENERAL_LINKS_MAIN,
      imarketWebsite: process.env.GENERAL_LINKS_IMARKET
    },
    forms: {
      maxFileSize: process.env.MAX_FILE_SIZE
    },
    templatesFormConfig: {
      maxFileSize: process.env.MAX_FILE_SIZE,
      maxFileSizeBulkload: process.env.MAX_FILE_SIZE_BULKLOAD
    },
    sensitiveData: {
      notificationTimeout: Number(process.env.NOTIFICATION_TIMEOUT)
    },
    services: {
      hostRest: process.env.HOST_REST,
      endpoint: process.env.SERVICE_FILES_ENDPOINT,
      maxFileSize: parseInt(process.env.MAX_FILE_SIZE!),
      allowedAttachments: process.env.SERVICES_ALLOWED_FILE_EXTENSIONS?.split(' '),
      allowedAcknowledgementAttachments:
        process.env.SERVICES_ACKNOWLEDGEMENT_FILE_EXTENSIONS?.split(' '),
      contactNumber: process.env.CONTACT_NUMBER2
    },
    email: {
      maxNameLength: parseInt(process.env.EMAIL_MAX_FILE_NAME_LENGTH!),
      maxFileSize: parseInt(process.env.EMAIL_MAX_FILE_SIZE!),
      allowedAttachments: process.env.EMAIL_ALLOWED_ATTACHMENT_EXTENSIONS?.split(' '),
      captchaKey: process.env.RECAPTCHA_SITE_KEY,
      hostRest: process.env.HOST_REST,
      endpoint: process.env.EMAIL_ENDPOINT
    },
    reportScheduler: {
      hostRest: process.env.HOST_REST,
      endpoint: process.env.REPORTS_ENDPOINT
    },
    liveChat: {
      notificationTimeout: Number(process.env.NOTIFICATION_TIMEOUT)
    },
    templateRender: {
      host: process.env.HOST_REST,
      clickThruEndpoint: process.env.CLICK_THRU_ENDPOINT,
      informationEndpoint: process.env.INFORMATION_ENDPOINT,
      displayTemplateEndpoint: process.env.TEMPLATE_ENDPOINT,
      longListEndpoint: process.env.LONGLIST_ENDPOINT
    }
  },
  client: () => client
} as ApplicationConfig;
